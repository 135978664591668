import React, {useEffect, useState} from "react";

const LoadingBar: React.FC<{ style?: object, percent?: number }> = (props) => {
  const {
    style,
    percent = 0,
  } = props;
  
  const [isComplete, setIsComplete] = useState(false);

  const rBaseVal = 59.8;
  const strokeDasharray = rBaseVal * 2 * Math.PI;
  let strokeDashoffset = strokeDasharray - ((percent / 100) * strokeDasharray);
  strokeDashoffset = strokeDashoffset < 0 ? 0 : strokeDashoffset;

  useEffect(() => {
    if (percent >= 100) {
      setTimeout(() => {
        setIsComplete(true);
      }, 700);
    }
  }, [percent]);

  return (
    <div style={style} className={`container-loading ${isComplete ? 'complete' : ''}`}>
      <div className="loading-inner">
        <div className="loading-indicator">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 130 130"
            overflow="visible"
            enableBackground="new 0 0 130 130"
            id="progress"
          >
            <circle
              fill="none"
              opacity={1}
              stroke="#000000"
              strokeWidth="4"
              strokeMiterlimit="10"
              cx="64.8"
              cy="64.8"
              r="59.8"
            />
            <circle
              className="loading-circle"
              fill="none"
              opacity={1}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              stroke="#000000"
              strokeWidth="4"
              strokeMiterlimit="10"
              cx="64.8"
              cy="64.8"
              r={rBaseVal}
            />
          </svg>
        </div>
        <div className="loading-text">
          <p><b className="percentage">{percent}%</b></p>
        </div>
      </div>
    </div>
  );
}

export default LoadingBar;
