export interface IVideoAudioObject {
  video: string;
  audio: string;
}

export type IVideoAudioMap = Record<string, IVideoAudioObject>;

export interface IImageObject {
  _key: string;
  filename?: string;
  original?: string;
  originalCloudinary: string;
  originalClass: string;
}

export type IImageList = IImageObject[];

export const HOME_KEY = 'intro';

export const VIDEO_AUDIO_MAP: IVideoAudioMap = {
  [HOME_KEY]: {
    video: "video/Homepage.mp4",
    audio: "audio/homepage2.mp3",
  },
  a: {video: "video/A_art_nouveau_1.mp4", audio: "audio/artnouveau.mp3"},
  b: {video: "video/B_bauhaus_1.mp4", audio: "audio/bauhaus.mp3"},
  c: {video: "video/C_cubism_1.mp4", audio: "audio/cubism.mp3"},
  d: {video: "video/D_dadaism_1.mp4", audio: "audio/dadaism.mp3"},
  e: {video: "video/E_expressionism_1.mp4", audio: "audio/expressionism.mp3"},
  f: {video: "video/F_fauvism_1.mp4", audio: "audio/Fauvism.mp3"},
  g: {
    video: "video/G_glass_painting_1.mp4",
    audio: "audio/glasspainting.mp3",
  },
  h: {video: "video/H_handrawing_1.mp4", audio: "audio/handrawing.mp3"},
  i: {video: "video/I_impressionism_1.mp4", audio: "audio/Impressionism.mp3"},
  j: {video: "video/J_japonism_1.mp4", audio: "audio/Japonism.mp3"},
  k: {video: "video/K_kawaii_1.mp4", audio: "audio/kawaii.mp3"},
  l: {video: "video/L_lofi_1.mp4", audio: "audio/lofi.mp3"},
  m: {video: "video/M_mosaic_1.mp4", audio: "audio/Mosaic.mp3"},
  n: {video: "video/N_nostalgia_1.mp4", audio: "audio/nostagia.mp3"},
  o: {video: "video/O_op_art_1.mp4", audio: "audio/op_art.mp3"},
  p: {video: "video/P_Psychedelic_1.mp4", audio: "audio/PSYCHEDELIA.mp3"},
  q: {video: "video/Q_queencore_1.mp4", audio: "audio/queencore.mp3"},
  r: {
    video: "video/R_retro_futurism_1.mp4",
    audio: "audio/retro_futurism.mp3",
  },
  s: {video: "video/S_surrealism_1.mp4", audio: "audio/surrealism.mp3"},
  t: {video: "video/T_textile_1.mp4", audio: "audio/Textile.mp3"},
  u: {video: "video/U_utopia_1.mp4", audio: "audio/utopia.mp3"},
  v: {video: "video/V_Vaporwave_1.mp4", audio: "audio/vaporwave.mp3"},
  w: {video: "video/W_woodcut_1.mp4", audio: "audio/woodcut.mp3"},
  x: {video: "video/X_xenomorph_1.mp4", audio: "audio/xenomorph.mp3"},
  y: {video: "video/Y_y2k_1.mp4", audio: "audio/y2k.mp3"},
  z: {video: "video/Z_zine_1.mp4", audio: "audio/zine.mp3"},
};

export const IMAGE_LIST: IImageList = [
  {
    _key: HOME_KEY,
    filename: "Homepage.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478076/projects/hienle/Homepage.webp",
    originalClass: "image",
  },
  {
    _key: 'a',
    filename: "A_art_nouveau.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478724/projects/hienle/A_art_nouveau.webp",
    originalClass: "image",
  },
  {
    _key: 'b',
    filename: "B_bauhaus.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478724/projects/hienle/B_bauhaus.webp",
    originalClass: "image",
  },
  {
    _key: 'c',
    filename: "C_cubism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478724/projects/hienle/C_cubism.webp",
    originalClass: "image",
  },
  {
    _key: 'd',
    filename: "D_dadaism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478725/projects/hienle/D_dadaism.webp",
    originalClass: "image",
  },
  {
    _key: 'e',
    filename: "E_expressionism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478725/projects/hienle/E_expressionism.webp",
    originalClass: "image",
  },
  {
    _key: 'f',
    filename: "F_fauvism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478725/projects/hienle/F_fauvism.webp",
    originalClass: "image",
  },
  {
    _key: 'g',
    filename: "G_glass_painting.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478725/projects/hienle/G_glass_painting.webp",
    originalClass: "image",
  },
  {
    _key: 'h',
    filename: "H_handrawing.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478356/projects/hienle/H_handrawing.webp",
    originalClass: "image",
  },
  {
    _key: 'i',
    filename: "I_impressionism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478725/projects/hienle/I_impressionism.webp",
    originalClass: "image",
  },
  {
    _key: 'j',
    filename: "J_japonism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478726/projects/hienle/J_japonism.webp",
    originalClass: "image",
  },
  {
    _key: 'k',
    filename: "K_kawaii.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478726/projects/hienle/K_kawaii.webp",
    originalClass: "image",
  },
  {
    _key: 'l',
    filename: "L_lofi.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478727/projects/hienle/L_lofi.webp",
    originalClass: "image",
  },
  {
    _key: 'm',
    filename: "M_mosaic.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478727/projects/hienle/M_mosaic.webp",
    originalClass: "image",
  },
  {
    _key: 'n',
    filename: "N_nostalgia.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478727/projects/hienle/N_nostalgia.webp",
    originalClass: "image",
  },
  {
    _key: 'o',
    filename: "O_op_art.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478727/projects/hienle/O_op_art.webp",
    originalClass: "image",
  },
  {
    _key: 'p',
    filename: "P_Psychedelia.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478726/projects/hienle/P_Psychedelia.webp",
    originalClass: "image",
  },
  {
    _key: 'q',
    filename: "Q_queencore.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478727/projects/hienle/Q_queencore.webp",
    originalClass: "image",
  },
  {
    _key: 'r',
    filename: "R_retro_futurism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478728/projects/hienle/R_retro_futurism.webp",
    originalClass: "image",
  },
  {
    _key: 's',
    filename: "S_surrealism.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478727/projects/hienle/S_surrealism.webp",
    originalClass: "image",
  },
  {
    _key: 't',
    filename: "T_textile.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478728/projects/hienle/T_textile.webp",
    originalClass: "image",
  },
  {
    _key: 'u',
    filename: "U_utopia.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478728/projects/hienle/U_utopia.webp",
    originalClass: "image",
  },
  {
    _key: 'v',
    filename: "V_vaporwave.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478728/projects/hienle/V_vaporwave.webp",
    originalClass: "image",
  },
  {
    _key: 'w',
    filename: "W_woodcut.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478729/projects/hienle/W_woodcut.webp",
    originalClass: "image",
  },
  {
    _key: 'x',
    filename: "X_xenomorph.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478729/projects/hienle/X_xenomorph.webp",
    originalClass: "image",
  },
  {
    _key: 'y',
    filename: "Y_Y2K.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478729/projects/hienle/Y_Y2K.webp",
    originalClass: "image",
  },
  {
    _key: 'z',
    filename: "Z_zine.webp",
    originalCloudinary: "https://res.cloudinary.com/mydata/image/upload/v1690478729/projects/hienle/Z_zine.webp",
    originalClass: "image",
  },
];

export const DIRECTORY_NAME = {
  IMAGES_MOBILE: 'images/webp/mobile/',
  IMAGES_IPAD_VERTICAL: 'images/webp/ipad-vertical/',
  IMAGES_IPAD_HORIZONTAL: 'images/webp/ipad-horizontal/',
  IMAGES_GIF: 'images/gif/',
}

export const DIRECTORY_NAME_IMAGEKIT = {
  IMAGES_MOBILE: 'https://ik.imagekit.io/doconline/hl-projects/webp/mobile/',
  IMAGES_IPAD_VERTICAL: 'https://ik.imagekit.io/doconline/hl-projects/webp/ipad-vertical/',
  IMAGES_IPAD_HORIZONTAL: 'https://ik.imagekit.io/doconline/hl-projects/webp/ipad-horizontal/',
  IMAGES_GIF: 'images/gif/',
}

export const NUMBER_TO_CHARACTER_LIST = [
  HOME_KEY,
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const VOLUME_INIT: number = 0.4

export const VOLUME_STEP: number = 0.2
