import React, {useEffect, useRef, useState} from "react";
import ImageGallery from "react-image-gallery";
import {useGlobalAudioPlayer} from "react-use-audio-player";
import {
  isMobile as isMobileDetect,
  deviceType,
  useMobileOrientation
} from 'react-device-detect';
import "./styles/App.scss";
import {
  DIRECTORY_NAME,
  HOME_KEY,
  IImageObject,
  IMAGE_LIST,
  NUMBER_TO_CHARACTER_LIST,
  VIDEO_AUDIO_MAP,
  VOLUME_INIT,
  VOLUME_STEP,
} from "./data";
import LoadingBar from "./components/LoadingBar";

const calculatePercentLoaded = (count: number, maxCount: number) => {
  let percent = (count / maxCount) * 100;
  percent = parseInt(percent.toFixed(0), 10);
  percent = percent < 0 ? 0 : percent;
  percent = percent > 100 ? 100 : percent;
  return percent;
}

const getStoreBlobRefByKey = (storeData: { name: string, blob: Blob }[], key: string): Blob | null => {
  const result = storeData.find(i => i.name === key)
  return result?.blob ?? null
}

function App() {
  const DB_NAME_OLD = ['videosDB', 'videoAndImageDB'];
  const DB_NAME = 'videoAndImageDbVer5';
  const DB_VERSION = 5;
  const OBJECT_STORE_NAME = 'videos';
  const IMAGES_OBJECT_STORE_NAME = 'images';
  const LOADED_TIMEOUT = 1200;
  const [preloadHomeVideo, setPreloadHomeVideo] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isKeyDownRef = useRef(false);
  let db = useRef<any>(null);
  const isFirstClickedRef = useRef<boolean>(false);
  const {load, setVolume} = useGlobalAudioPlayer();
  const volumeRef = useRef<number>(VOLUME_INIT);
  const galleryRef = useRef<ImageGallery>(null);
  const touchRef = useRef<boolean>(false);
  const maxGalleryIndex = IMAGE_LIST.length - 1;
  const touchTimeoutRef = useRef<any>(null);
  const firstRenderRef = useRef(false)
  const storeBlobRef = useRef<{ name: string, blob: Blob }[]>([])
  const [countLoaded, setCountLoaded] = useState(0)
  const [isMobileOrIpad, setIsMobileOrIpad] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const MAX_COUNT_LOADED_DESKTOP = Object.keys(VIDEO_AUDIO_MAP).length;
  const MAX_COUNT_LOADED_MOBILE = IMAGE_LIST.length;
  const percentLoaded = calculatePercentLoaded(countLoaded, isMobileOrIpad ? MAX_COUNT_LOADED_MOBILE : MAX_COUNT_LOADED_DESKTOP);
  const imagesListRef: React.MutableRefObject<IImageObject[] | null> = useRef(null)
  const { isLandscape } = useMobileOrientation();

  function detectMobile() {
    const isDeviceTypeMobile = deviceType === 'mobile';
    const isMobileOrIpad = isMobileDetect;
    const isMobileDevice = isMobileDetect && isDeviceTypeMobile;
    const isIpadDevice = isMobileDetect && !isDeviceTypeMobile;
    return {
      isMobileOrIpad,
      isMobileDevice,
      isIpadDevice,
    }
  }

  const loadSound = (soundPath: string) => {
    load(soundPath, {
      loop: true,
      autoplay: true,
      html5: false,
      initialVolume: volumeRef.current,
    });
  }

  const checkAndRemoveOldVersionDb = () => {
    DB_NAME_OLD.forEach(i => indexedDB.deleteDatabase(i));
  }

  const handleIdbOnUpgradeNeeded = (ev: Event) => {
    const db = (ev.target as IDBOpenDBRequest).result;
    db.createObjectStore(OBJECT_STORE_NAME, {
      keyPath: "name",
    });
    db.createObjectStore(IMAGES_OBJECT_STORE_NAME, {
      keyPath: '_key',
    });
  };

  const convertBlobToImagesList = (callback: Function) => {
    if (db && db.current) {
      const objectStore: IDBObjectStore = db.current.transaction([IMAGES_OBJECT_STORE_NAME]).objectStore(IMAGES_OBJECT_STORE_NAME);
      const request = objectStore.getAll();
      request.onerror = () => {
        console.error("error");
      };
      request.onsuccess = () => {
        let dataImagesResult;
        
        if (storeBlobRef.current.length) {
          dataImagesResult = storeBlobRef.current.map(i => ({_key: i.name, blob: i.blob}))
        } else {
          dataImagesResult = request.result;
        }
        
        let homeItem: IImageObject = {} as IImageObject;
        const imagesListTmp = dataImagesResult.map(i => {
          const urlImage = URL.createObjectURL(i.blob);
          if (i._key === HOME_KEY) {
            homeItem = {
              _key: i._key,
              original: urlImage,
              originalCloudinary: urlImage,
              originalClass: "image",
            }
          }
          return {
            _key: i._key,
            original: urlImage,
            originalCloudinary: urlImage,
            originalClass: "image",
          }
        });

        imagesListRef.current = imagesListTmp.filter(f => f._key !== HOME_KEY);
        imagesListRef.current.unshift(homeItem);
        callback();
      };
    }
  }

  const fetchHomeVideo = async () => {
    const data = await fetch("video/Homepage.mp4");
    const blob = await data.blob();
    if (videoRef && videoRef.current) {
      videoRef.current.setAttribute("src", window.URL.createObjectURL(blob));
      setPreloadHomeVideo(false);
    }
  };

  const prefetchVideos = () => {
    for (let key in VIDEO_AUDIO_MAP) {
      const videoRequest = fetch(
        VIDEO_AUDIO_MAP[key as keyof typeof VIDEO_AUDIO_MAP].video
      ).then((response) => response.blob());

      videoRequest.then((blob) => {
        checkAndRemoveOldVersionDb();
        const request = indexedDB.open(DB_NAME, DB_VERSION);
        request.onupgradeneeded = handleIdbOnUpgradeNeeded;

        request.onsuccess = ev => {
          db.current = (ev.target as IDBOpenDBRequest).result;
          const objectStore = db.current.transaction([OBJECT_STORE_NAME], 'readwrite').objectStore(OBJECT_STORE_NAME);
          try {
            objectStore.put({name: key, blob: blob});
          } catch (e) {
            storeBlobRef.current.push({name: key, blob: blob})
          }
          setCountLoaded(prev => ++prev);
        };
      });
    }
  };

  const prefetchForMobile = (imagesPathName = DIRECTORY_NAME.IMAGES_MOBILE) => {
    IMAGE_LIST.forEach(item => {
      const imageRequest = fetch(imagesPathName + item.filename).then((response) => response.blob());
      imageRequest.then((blob) => {
        checkAndRemoveOldVersionDb();
        const request = indexedDB.open(DB_NAME, DB_VERSION);
        request.onupgradeneeded = handleIdbOnUpgradeNeeded;

        request.onsuccess = (ev: Event) => {
          db.current = (ev.target as IDBOpenDBRequest).result;
          const objectStore = db.current.transaction([IMAGES_OBJECT_STORE_NAME], 'readwrite').objectStore(IMAGES_OBJECT_STORE_NAME);
          try {
            objectStore.put({_key: item._key, blob: blob});
          } catch (e) {
            storeBlobRef.current.push({name: item._key, blob: blob})
          }
          setCountLoaded(prev => ++prev);
        };
      });
    })
  };

  const handleChangeVolume = (event: KeyboardEvent) => {
    if (!['ArrowUp', 'ArrowDown'].includes(event.code)) return;

    let newVolume = volumeRef.current;
    // Arrow Up
    if (event.code === 'ArrowUp') {
      newVolume += VOLUME_STEP;
      if (newVolume > 1) newVolume = 1;
    }
    // Arrow Down
    if (event.code === 'ArrowDown') {
      newVolume -= VOLUME_STEP;
      if (newVolume < 0) newVolume = 0;
    }
    volumeRef.current = parseFloat(newVolume.toFixed(2));
    setVolume(newVolume);
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    isFirstClickedRef.current = true;
    if (isKeyDownRef.current) {
      return;
    }
    isKeyDownRef.current = true;

    if (['ArrowUp', 'ArrowDown'].includes(event.code)) {
      handleChangeVolume(event);
      return;
    }

    const keyFormatted = (event.key).toLowerCase();
    const key = keyFormatted as keyof typeof VIDEO_AUDIO_MAP;
    const listCharacter = Object.keys(VIDEO_AUDIO_MAP) as [
      keyof typeof VIDEO_AUDIO_MAP
    ];
    if (!listCharacter.includes(key)) {
      return;
    }
    if (db && db.current) {
      const objectStore = db.current.transaction([OBJECT_STORE_NAME]).objectStore(OBJECT_STORE_NAME);
      const test = objectStore.get(key);
      test.onerror = () => {
        console.error("error");
      };
      test.onsuccess = () => {
        if (videoRef && videoRef.current) {
          const blobData = test?.result?.blob ?? getStoreBlobRefByKey(storeBlobRef.current, key)
          if (blobData) {
            videoRef.current.setAttribute(
              "src",
              window.URL.createObjectURL(blobData)
            );
          }
          loadSound(VIDEO_AUDIO_MAP[key as keyof typeof VIDEO_AUDIO_MAP].audio);
        }
      };
    }
  };

  const reset = (event: KeyboardEvent) => {
    isKeyDownRef.current = false;

    if (['ArrowUp', 'ArrowDown'].includes(event.code)) {
      return;
    }

    if (db && db.current) {
      const objectStore = db.current.transaction([OBJECT_STORE_NAME]).objectStore(OBJECT_STORE_NAME);
      const test = objectStore.get("intro");
      test.onerror = () => {
        console.error("error");
      };
      test.onsuccess = () => {
        if (videoRef && videoRef.current) {
          const blobData = test?.result?.blob ?? getStoreBlobRefByKey(storeBlobRef.current, "intro")
          if (blobData) {
            videoRef.current.setAttribute(
              "src",
              window.URL.createObjectURL(blobData)
            );
          }
          loadSound(VIDEO_AUDIO_MAP.intro.audio);
        }
      };
    }
  };

  const handleSlide = (index: any) => {
    const key = NUMBER_TO_CHARACTER_LIST[index];
    loadSound(VIDEO_AUDIO_MAP[key as keyof typeof VIDEO_AUDIO_MAP].audio);
  };

  const handleFirstClickedPlaySound = () => {
    if (isFirstClickedRef.current) return;
    isFirstClickedRef.current = true;
    loadSound(VIDEO_AUDIO_MAP.intro.audio);
  }

  const handleRandomIndex = () => {
    // Always min is 1
    const rndInt = Math.floor(Math.random() * (maxGalleryIndex + 1)) + 1
    return rndInt - 1
  }

  const onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (touchRef.current) return;
    touchRef.current = true;

    // Slide to random index
    touchTimeoutRef.current = setTimeout(() => {
      if (galleryRef.current) {
        const indexRandom = handleRandomIndex()
        galleryRef.current.slideToIndex(indexRandom)
      }
    }, 200)

    return false;
  }

  const onTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
    touchRef.current = false;

    // Reset to homepage
    clearTimeout(touchTimeoutRef.current)
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(0)
    }
    return false;
  }

  const resetLoading = () => {
    setCountLoaded(0);
    setIsLoading(true);
  }

  useEffect(() => {
    // React.Strict mode is on make useEffect render more than once
    if (firstRenderRef.current) return;
    firstRenderRef.current = true;
    const resultDetectMobile = detectMobile();
    const isMobileOrIpad = resultDetectMobile.isMobileOrIpad;
    setIsMobileOrIpad(isMobileOrIpad);

    // is Desktop
    if (!isMobileOrIpad) {
      fetchHomeVideo().then();
      prefetchVideos();
      window.addEventListener("keydown", handleKeyPress, false);
      window.addEventListener("keyup", reset, false);
      window.addEventListener("onclick", handleFirstClickedPlaySound, false);
    }

    // is Mobile
    if (isMobileOrIpad) {
      // Set max volume for mobile
      volumeRef.current = 1;
      window.addEventListener("contextmenu", (event: MouseEvent) => {
        event?.preventDefault();
        event?.stopPropagation();
        return false;
      }, false);

      // For Ipad
      if (resultDetectMobile.isIpadDevice) {
        // First load for Ipad
        const imagesPathName = isLandscape ? DIRECTORY_NAME.IMAGES_IPAD_HORIZONTAL : DIRECTORY_NAME.IMAGES_IPAD_VERTICAL
        prefetchForMobile(imagesPathName);
      }

      // For Mobile
      if (resultDetectMobile.isMobileDevice) {
        // First load for Mobile
        const imagesPathName = isLandscape ? DIRECTORY_NAME.IMAGES_IPAD_HORIZONTAL : DIRECTORY_NAME.IMAGES_MOBILE
        prefetchForMobile(imagesPathName);
      }
    }

    // Play music when access page
    loadSound(VIDEO_AUDIO_MAP.intro.audio);
  }, []);

  useEffect(() => {
    // is Mobile when isLandscape change 
    if (isMobileOrIpad) {
      const resultDetectMobile = detectMobile();
      resetLoading();
      // For Ipad
      if (resultDetectMobile.isIpadDevice) {
        const imagesPathName = isLandscape ? DIRECTORY_NAME.IMAGES_IPAD_HORIZONTAL : DIRECTORY_NAME.IMAGES_IPAD_VERTICAL
        prefetchForMobile(imagesPathName);
      }
      // For Mobile
      if (resultDetectMobile.isMobileDevice) {
        const imagesPathName = isLandscape ? DIRECTORY_NAME.IMAGES_IPAD_HORIZONTAL : DIRECTORY_NAME.IMAGES_MOBILE
        prefetchForMobile(imagesPathName);
      }
    }
  }, [isMobileOrIpad, isLandscape]);

  useEffect(() => {
    // Case Desktop
    if (!isMobileOrIpad && isLoading && percentLoaded === 100) {
      setTimeout(() => {
        setIsLoading(false);
      }, LOADED_TIMEOUT);
    }
    // Case Mobile
    if (isMobileOrIpad && isLoading && percentLoaded === 100) {
      convertBlobToImagesList(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, LOADED_TIMEOUT);
      });
    }
  }, [isLoading, countLoaded, percentLoaded])

  return (
    <div className="App">
      {!isLoading && !isMobileOrIpad && (
        <video
          style={{position: "relative", zIndex: preloadHomeVideo ? 10 : 1000}}
          width="100%"
          height="100%"
          controls={false}
          loop
          muted
          autoPlay
          preload="auto"
          ref={videoRef}
          src="video/Homepage.mp4"
        />
      )}
      {!isLoading && isMobileOrIpad && (
        <div className="gallery">
          <ImageGallery
            ref={galleryRef}
            items={imagesListRef.current as []}
            showFullscreenButton={false}
            showPlayButton={false}
            showNav={false}
            onSlide={handleSlide}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            useTranslate3D={false}
            disableSwipe
          />
        </div>
      )}
      {isLoading && (
        <LoadingBar percent={percentLoaded}/>
      )}
    </div>
  );
}

export default App;
